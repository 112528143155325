.encabezado{
    width: 100%;
    height: 55px;
    display: flex;
}
.encabezado img{
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: #fff;
}
.encabezado span{
    font-size: 65%;
}
.encL a{
    display: flex;
    align-items: center;
    letter-spacing: 3px;
    gap: 1rem;
    font-size: 160%;
    font-weight: bold;
    color: black;
    opacity: .8;
    color: rgb(255, 255, 255);
    padding: 3px 0px;
    margin: 0px 20px;
    text-transform: uppercase;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
}
.encC{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    font-size: 110%;
    font-weight: 600;
    letter-spacing: 3px;
    opacity: .8;
    color: #fff;
    padding: 3px 0px;
    margin: 0px 20px;
    text-transform: uppercase;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
}
.encC>a{color: var(--blancoOscuro);}

.encR{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 3px;
    opacity: .8;
    color: #fff;
    padding: 3px 10%;
    margin: 0px 20px;
    text-transform: uppercase;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent; 
}
.encR>a{color: var(--blancoOscuro);}


@media screen and (max-width:760px) {
    .encC{display: none;}
}