
.img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.prin img{
width: 60%;
}
.textA{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    color: var(--blancoOscuro);
    font-size:65%;
}

.textA>div{
    display: flex;
    width: 8%;
    height: 1px;
    background-color: var(--blancoOscuro);
  
}