*{ text-decoration: none; font-family: "Reaver",serif;}

video{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
:root{
  --blancoOscuro:rgba(255, 255, 255, 0.842);
}
