.tabla{
    width: 100%;
    height: 905px;
    color: white;
}
.tablaP{
  display: flex;
}
.span1{
    width: 90%;
   
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
}
.span2{
    display: none;
}
.span3{
    width: 100%;
    height: 4.8%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
}
.span4{
    display: none;
}
h1{
    font-size: 150%; 
    text-align: center;
}
h1:hover{
    color: aqua;
}

@media screen and (max-width:750px) {
    .span1{display: none;}
    .span2{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60%;
    } 

    .span3{display: none;}
    .span4{
        width: 90%;
        height: 4.8%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60%;
    } 
   
}
